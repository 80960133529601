<template>
  <div class="wrapperDiv">
    <h1>
      <span>Exam Submitted <v-icon class="main-color">thumb_up</v-icon></span>
    </h1>
    <h3>
      <router-link to="/" style="color: #7295fb">
        {{ $t("Back to home") }}
      </router-link>
    </h3>
  </div>
</template>

<script>
export default {
  name: "examSubmitted",
};
</script>

<style lang="css" scoped>
.wrapperDiv {
  text-align: center;
  margin-top: 200px;
}
h1 {
  font-size: 100px;
  color: #7295fb;
}
h1 .v-icon {
  font-size: 100px;
}
h1 span {
  border-bottom: 1px solid;
}
h1,
h3 {
  margin-bottom: 0;
  font-family: sans-serif;
}
h3 {
  color: #676767;
}
img {
  color: #7295fb;
}
</style>
